<template>
  <div style="display: contents">
    <v-select
      outlined
      dense
      v-model="selectedFilter"
      v-if="name == 'agnt' && id == 18"
      :items="[
        { text: 'All', value: null },
        { text: 'Direct Hire', value: 18 },
        { text: 'Agency', value: 10 }
      ]"
      label="Filter agents by"
    ></v-select>
    <v-select
      outlined
      dense
      v-model="selectedFilter"
      v-if="name == 'prj'"
      :items="getRecentYears"
      label="Filter projects by year"
    ></v-select>

    <v-autocomplete
      v-if="['agnt', 'prj'].includes(name)"
      :name="name"
      v-model="value"
      :items="sortedLovs"
      outlined
      chips
      color="blue-grey lighten-2"
      :label="labelName"
      multiple
      :rules="[(value) => value.length > 0 || 'Required']"
      clearable
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="remove(data.item)"
        >
          {{ data.item.text }}
        </v-chip>
      </template>
      <v-list-item slot="prepend-item" @click="selectAll()">
        <v-list-item-title>Select All</v-list-item-title>
      </v-list-item>
      <v-divider slot="prepend-item" class="mt-2" />
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title v-html="data.item.text"></v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>

    <template v-else-if="name == 'date'">
      <div class="d-flex">
        <v-menu
          v-for="dateType in ['From', 'To']"
          :key="dateType"
          v-model="dateMenu[dateType]"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :class="dateType == 'To' || 'mr-2'"
              append-icon="mdi-calendar"
              outlined
              dense
              :name="dateType"
              :rules="[(v) => !!v || 'Required']"
              :value="value[dateType]"
              :label="dateType"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            @input="
              value[dateType] = $event;
              dateMenu[dateType] = false;
            "
          ></v-date-picker>
        </v-menu>
      </div>
    </template>

    <v-autocomplete
      outlined
      dense
      v-model="value"
      v-else-if="['bdm', 'clt'].includes(name)"
      :items="lovs"
      :label="labelName"
      :name="name"
    ></v-autocomplete>

    <v-select
      outlined
      dense
      v-model="value"
      v-else-if="name == 'recsrc'"
      :items="lovs"
      :label="labelName"
      :name="name"
    ></v-select>

    <v-select
      outlined
      dense
      v-else-if="name == 'year'"
      :items="getRecentYears"
      :name="yyear"
      label="Year"
    ></v-select>

    <template v-else-if="name == 'tssearch'">
      <div class="d-flex">
        <div class="pt-2 mr-2">Timesheet No:</div>
        <v-text-field
          dense
          name="txtTimeSheetNr1"
          label="NI"
          style="max-width:120px"
          maxlength="9"
        ></v-text-field>
        <div class="pa-2 font-weight-light">/</div>
        <v-text-field
          dense
          name="txtTimeSheetNr2"
          label="Agency ID"
          style="max-width:110px"
          maxlength="2"
        ></v-text-field>
        <div class="pa-2 font-weight-light">/</div>
        <v-text-field
          dense
          name="txtTimeSheetNr3"
          label="Week of Year"
          style="max-width:110px"
          maxlength="2"
        ></v-text-field>
        <div class="pa-2 font-weight-light">/</div>
        <v-text-field
          dense
          name="txtTimeSheetNr4"
          label="Year"
          style="max-width:100px"
          maxlength="4"
        ></v-text-field>
      </div>
      <p class="text-left subtitle-2 font-weight-light font-italic">
        <b>Search Format:</b> (* means wildcard * may be used.) eg:*/*/15/2021
      </p>
    </template>
  </div>
</template>

<script>
export default {
  props: ["name", "lovs", "id"],
  data() {
    return {
      dateMenu: [],
      selectedFilter: null,
      value: []
    };
  },
  computed: {
    getRecentYears() {
      let years = [{ text: "All", value: null }];
      const currentYear = new Date().getFullYear();
      for (let i = currentYear; i > currentYear - 3; i--) {
        years.push(i);
      }
      return years;
    },
    sortedLovs() {
      let lovs = [];
      lovs = this.lovs;
      if (this.selectedFilter) {
        if (this.name == "agnt") {
          lovs = lovs.filter((e) => e.recId == this.selectedFilter);
        } else if (this.name == "prj") {
          lovs = lovs.filter(
            (e) => this.selectedFilter >= e.startYear && this.selectedFilter <= e.endYear
          );
        }
      }
      return lovs;
    },
    labelName() {
      let name = "-";
      switch (this.name) {
        case "date":
          name = "Date";
          break;
        case "agnt":
          name = "Agent";
          break;
        case "prj":
          name = "Project";
          break;
        case "bdm":
          name = "BDM";
          break;
        case "clt":
          name = "Client";
          break;
        case "recsrc":
          name = "Agency";
          break;
      }
      return name;
    }
  },
  created() {
    if (this.name == "date") {
      this.value["From"] = new Date(Date.now() - 604800000).toJSON().slice(0, 10);
      this.value["To"] = new Date().toJSON().slice(0, 10);
    } else {
      this.value = [];
    }
  },
  methods: {
    selectAll() {
      this.value = this.lovs.map((e) => {
        return e.value;
      });
    },
    remove(item) {
      const index = this.value.indexOf(item.value);
      if (index >= 0) this.value.splice(index, 1);
    }
  }
};
</script>
