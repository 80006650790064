var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"contents"}},[(_vm.name == 'agnt' && _vm.id == 18)?_c('v-select',{attrs:{"outlined":"","dense":"","items":[
      { text: 'All', value: null },
      { text: 'Direct Hire', value: 18 },
      { text: 'Agency', value: 10 }
    ],"label":"Filter agents by"},model:{value:(_vm.selectedFilter),callback:function ($$v) {_vm.selectedFilter=$$v},expression:"selectedFilter"}}):_vm._e(),(_vm.name == 'prj')?_c('v-select',{attrs:{"outlined":"","dense":"","items":_vm.getRecentYears,"label":"Filter projects by year"},model:{value:(_vm.selectedFilter),callback:function ($$v) {_vm.selectedFilter=$$v},expression:"selectedFilter"}}):_vm._e(),(['agnt', 'prj'].includes(_vm.name))?_c('v-autocomplete',{attrs:{"name":_vm.name,"items":_vm.sortedLovs,"outlined":"","chips":"","color":"blue-grey lighten-2","label":_vm.labelName,"multiple":"","rules":[function (value) { return value.length > 0 || 'Required'; }],"clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({attrs:{"input-value":data.selected,"close":""},on:{"click":data.select,"click:close":function($event){return _vm.remove(data.item)}}},'v-chip',data.attrs,false),[_vm._v(" "+_vm._s(data.item.text)+" ")])]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.text)}})],1)]}}],null,false,3509493134),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-list-item',{attrs:{"slot":"prepend-item"},on:{"click":function($event){return _vm.selectAll()}},slot:"prepend-item"},[_c('v-list-item-title',[_vm._v("Select All")])],1),_c('v-divider',{staticClass:"mt-2",attrs:{"slot":"prepend-item"},slot:"prepend-item"})],1):(_vm.name == 'date')?[_c('div',{staticClass:"d-flex"},_vm._l((['From', 'To']),function(dateType){return _c('v-menu',{key:dateType,attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({class:dateType == 'To' || 'mr-2',attrs:{"append-icon":"mdi-calendar","outlined":"","dense":"","name":dateType,"rules":[function (v) { return !!v || 'Required'; }],"value":_vm.value[dateType],"label":dateType,"readonly":""}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.dateMenu[dateType]),callback:function ($$v) {_vm.$set(_vm.dateMenu, dateType, $$v)},expression:"dateMenu[dateType]"}},[_c('v-date-picker',{on:{"input":function($event){_vm.value[dateType] = $event;
            _vm.dateMenu[dateType] = false;}}})],1)}),1)]:(['bdm', 'clt'].includes(_vm.name))?_c('v-autocomplete',{attrs:{"outlined":"","dense":"","items":_vm.lovs,"label":_vm.labelName,"name":_vm.name},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):(_vm.name == 'recsrc')?_c('v-select',{attrs:{"outlined":"","dense":"","items":_vm.lovs,"label":_vm.labelName,"name":_vm.name},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):(_vm.name == 'year')?_c('v-select',{attrs:{"outlined":"","dense":"","items":_vm.getRecentYears,"name":_vm.yyear,"label":"Year"}}):(_vm.name == 'tssearch')?[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"pt-2 mr-2"},[_vm._v("Timesheet No:")]),_c('v-text-field',{staticStyle:{"max-width":"120px"},attrs:{"dense":"","name":"txtTimeSheetNr1","label":"NI","maxlength":"9"}}),_c('div',{staticClass:"pa-2 font-weight-light"},[_vm._v("/")]),_c('v-text-field',{staticStyle:{"max-width":"110px"},attrs:{"dense":"","name":"txtTimeSheetNr2","label":"Agency ID","maxlength":"2"}}),_c('div',{staticClass:"pa-2 font-weight-light"},[_vm._v("/")]),_c('v-text-field',{staticStyle:{"max-width":"110px"},attrs:{"dense":"","name":"txtTimeSheetNr3","label":"Week of Year","maxlength":"2"}}),_c('div',{staticClass:"pa-2 font-weight-light"},[_vm._v("/")]),_c('v-text-field',{staticStyle:{"max-width":"100px"},attrs:{"dense":"","name":"txtTimeSheetNr4","label":"Year","maxlength":"4"}})],1),_c('p',{staticClass:"text-left subtitle-2 font-weight-light font-italic"},[_c('b',[_vm._v("Search Format:")]),_vm._v(" (* means wildcard * may be used.) eg:*/*/15/2021 ")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }