<template>
  <v-container fluid>
    <v-card :loading="loading">
      <v-card-title class="indigo white--text headline">
        Report
      </v-card-title>
      <v-row class="pa-4" justify="space-between">
        <v-col cols="3">
          <v-treeview
            @update:active="goToReport"
            :active.sync="active"
            :items="items"
            :open.sync="open"
            activatable
            open-on-click
            transition
          >
          </v-treeview>
        </v-col>

        <v-divider vertical></v-divider>

        <v-col class="text-center">
          <v-scroll-y-transition mode="out-in">
            <div
              v-if="!selected"
              class="title grey--text text--lighten-1 font-weight-light"
              style="align-self: center;"
            >
              Select a report
            </div>
            <v-card v-else :key="selected.id" class="pa-6 mx-auto" flat>
              <v-card-title>
                {{ selected.name }}
              </v-card-title>
              <form
                :action="$store.state.auth.apihost.ccms_host + '/ccms/report/reportvue.htm'"
                method="post"
                target="report"
              >
                <v-radio-group v-model="searchType" row v-if="selected.id == 22">
                  <v-radio
                    v-for="(n, index) in ['Agency, Week Date and Agent', 'Timesheet No.']"
                    :key="index"
                    :label="n"
                    :value="index"
                    name="search_selection"
                  ></v-radio>
                </v-radio-group>
                <ReportFilterInput
                  v-for="(param, index) in selected.params"
                  :name="param"
                  :id="selected.id"
                  :lovs="lovs[param]"
                  :key="index"
                />
                <input type="hidden" :value="selected.id" name="reportId" />
                <input type="hidden" :value="$store.state.auth.token" name="jwt" />
                <v-btn block type="submit">Report HTML</v-btn>
              </form>
            </v-card>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import ReportFilterInput from "@/components/ccms/ReportFilterInput";
export default {
  components: {
    ReportFilterInput
  },
  computed: {
    reportId() {
      return this.$route.params.id;
    },
    selected() {
      if (!this.active.length) return undefined;
      const id = this.active[0];

      for (let val of Object.values(this.items)) {
        if (val.children) {
          for (let node of Object.values(val.children)) {
            node = { ...node };
            if (node.id == id) {
              if (id == 22) {
                let exclude = this.searchType == 0 ? ["tssearch"] : ["recsrc", "date"];
                node.params = Object.values(node.params).filter((e) => !exclude.includes(e));
              }
              return node;
            }
          }
        }
      }

      return null;
    }
  },
  data() {
    return {
      searchType: 0,
      lovs: {},
      reportName: "",
      loading: false,
      active: [],
      avatar: null,
      open: [],
      items: []
    };
  },
  created() {
    this.getReportInfo();
    this.active[0] = this.reportId;
  },
  methods: {
    goToReport(id) {
      this.$router.push({ params: { id: id[0] } });
      this.$vuetify.goTo(0);
    },
    getReportInfo() {
      this.loading = true;
      this.$axios
        .get("/ccms/report/info")
        .then((res) => {
          this.items = res.data.menu;
          this.lovs = res.data.lovs;
          this.open = [10000];
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
